<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="是否通知用戶"  prop="noticeStatus">
        <a-select v-model="form.noticeStatus" placeholder="请选择通知狀態" @change="changeStatus">
          <a-select-option :value="0">不通知</a-select-option>
          <a-select-option :value="1">通知</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="上架原因" prop="refusalCause" v-show="showVer">
        <a-input v-model="form.refusalCause" type="textarea" allow-clear placeholder="请输上架原因" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVideo, changeStatus1, updateVideo } from '@/api/platform/video'
import Editor from '@/components/Editor'

export default {
  name: 'OffShelfForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      showVer: false,
      submitLoading: false,
      formTitle: '',
      auditRejectTypeList:[],
      // 表单参数
      form: {
        id: null,
        noticeStatus: 0,
        refusalCause: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        noticeStatus: 0,
        refusalCause: null
      }
     /* this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })*/
    },
    changeStatus(status){
      if(status == 0){
        this.showVer = false;
      }
      if(status ==1){
        this.showVer = true;
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVideo({"id":id}).then(response => {
        this.form = response.data;
        this.form.status = 0;
        this.form.noticeStatus = 0;
        this.open = true
        this.formTitle = '上架'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            changeStatus1(this.form.id,this.form.refusalCause,this.form.noticeStatus).then(response => {
              this.$message.success(
                '上架成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
